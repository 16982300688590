import React,{useEffect, useState} from 'react';
import Api from '@/api';
import Menu from '../../guide/components/Menu';
import './WorksMain.scss';
import { navigate } from 'gatsby';

const MENULIST=[
  {title:'第五届', id:'match5'},
  {title:'第四届', id:'match4'}
]

const WorksMain =(props)=>{

  const [works, setWorks]=useState([]);
  const [lastWorks, setLastWorks]=useState([]);
  const [isLoading, setIsLoading]=useState(true);

  const getArticelList=()=>{
    Api.getArticleList({ 
      currentPage: 0, 
      isClient:1,
      numPerPage:200,
      category:5
    }).then(res=>{
      setWorks(res.recordList.filter(item => item.matchNum === 5));
      setLastWorks(res.recordList.filter(item => item.matchNum === 4));
    }).catch(err=>{

    }).finally(() => {
      setIsLoading(false)
    })
  }

  useEffect(()=>{
    getArticelList();
  },[])


  return <div className='works-main-box'>
      <div className="WorksMain">
        <Menu menuList={MENULIST}></Menu>
        <article className='content'>
          {
            !isLoading &&
            (<>
              <section className='match'>
                <h1 id="match5" className="main-title">{
                  works.length ? <img src={require('../../../assets/images/call-title.png').default} alt="" />:
                  <section>
                    <img src={require('../../../assets/images/match_banner5.png').default} alt="" />
                    <div>
                      <h2>第五届编创赛正式开启！</h2>
                      <a onClick={() => navigate('/guide')}>点击查看详情</a>
                    </div>
                  </section>}
                </h1>
                <ul className="works-list">
                  {works.map((item,index)=><li onClick={e=>{navigate('/news?id='+item.id)}} key={index}>
                    <div className="works-img">
                      <img src={item.cover}/>
                    </div>
                    <div className="works-content">
                      <h3 title={item.secondTitle+''+item.mainTitle+''}>{item.secondTitle}《{item.mainTitle}》</h3>
                      <p>{item.summary}</p>
                    </div>
                  </li>)}
                </ul>
              </section>
              <section className='last-match'>
                <h1 id="match4" className="main-title"><img src={require('../../../assets/images/last-call-title.png').default} alt="" /></h1>
                <ul className="works-list">
                  {lastWorks.map((item,index)=><li onClick={e=>{navigate('/news?id='+item.id)}} key={index}>
                    <div className="works-img">
                      <img src={item.cover}/>
                    </div>
                    <div className="works-content">
                      <h3 title={item.secondTitle+''+item.mainTitle+''}>{item.secondTitle}《{item.mainTitle}》</h3>
                      <p>{item.summary}</p>
                    </div>
                  </li>)}
                </ul>
              </section>
            </>
            )
          }
        </article>
    </div>
  </div>
}

export default WorksMain;

const WORKSLIST=[
  {img:'',title:'《是吃鱼还是吃米饭》',author:'陈大锤',remark:'NECIS2021 第一季度获奖作品'},
  {img:'',title:'《是吃鱼还是吃米饭》',author:'陈大锤',remark:'NECIS2021 第一季度获奖作品'},
  {img:'',title:'《是吃鱼还是吃米饭》',author:'陈大锤',remark:'NECIS2021 第一季度获奖作品'},
  {img:'',title:'《是吃鱼还是吃米饭》',author:'陈大锤',remark:'NECIS2021 第一季度获奖作品'},
  {img:'',title:'《是吃鱼还是吃米饭》',author:'陈大锤',remark:'NECIS2021 第一季度获奖作品'},
  {img:'',title:'《是吃鱼还是吃米饭》',author:'陈大锤',remark:'NECIS2021 第一季度获奖作品'},
  {img:'',title:'《是吃鱼还是吃米饭》',author:'陈大锤',remark:'NECIS2021 第一季度获奖作品'},
  {img:'',title:'《是吃鱼还是吃米饭》',author:'陈大锤',remark:'NECIS2021 第一季度获奖作品'},
  {img:'',title:'《是吃鱼还是吃米饭》',author:'陈大锤',remark:'NECIS2021 第一季度获奖作品'}
]