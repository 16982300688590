import React,{useEffect, useState} from 'react';
import {navigate} from 'gatsby';

import './Menu.scss';

const Menu=(props) =>{
  const [menuFixed,setMenuFixed] = useState(false);
  const [hoverIndex,setHoverIndex]=useState(-1);
  const MENULIST = props.menuList || []

  useEffect(()=>{
    const offsets=new Array(MENULIST.length)

    window.document.addEventListener('scroll',e=>{
      const scrollTop=document.documentElement.scrollTop?document.documentElement.scrollTop:window.pageYOffset;

      for(let i=0;i<offsets.length;i++){
        try {
          if (offsets[i] === undefined) offsets[i] = document.querySelector('#'+MENULIST[i].id).offsetTop+10;
          if(scrollTop<offsets[i]){
            setHoverIndex(i);
            break;
          }
        } catch (error) {}
      }
      


      if(scrollTop<317){
        setMenuFixed(false);
      }else{
        setMenuFixed(true);
      }
    })
    

  },[])

  function navgatorTo(url){
    navigate(url);
  }

  return <div className={`Menu ${menuFixed?'fixed':''}`}>
      <ul className="float-menu">
        {
          MENULIST.map((item,index)=>(
            <li className={hoverIndex==index && 'hover'} onClick={e=>navgatorTo('#'+item.id)} key={item.id}>{item.title}</li>
          ))
        }
      </ul>
  </div>
}

export default Menu;